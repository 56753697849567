// Generated by Framer (9bd94da)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["gtiYcpvr3", "BridPQHFS"];

const serializationHash = "framer-umRDU"

const variantClassNames = {BridPQHFS: "framer-v-67tri1", gtiYcpvr3: "framer-v-12r2jh7"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const humanReadableVariantMap = {"logo desktop": "gtiYcpvr3", "logo mobile": "BridPQHFS"}

const getProps = ({height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "gtiYcpvr3"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "gtiYcpvr3", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-umRDU", ...sharedStyleClassNames, classNames)} style={{display: "contents"}}>
<Transition value={transition}><motion.div {...restProps} className={cx("framer-12r2jh7", className)} data-framer-name={"logo desktop"} layoutDependency={layoutDependency} layoutId={"gtiYcpvr3"} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({BridPQHFS: {"data-framer-name": "logo mobile"}}, baseVariant, gestureVariant)}><Image background={{alt: "", fit: "fill", intrinsicHeight: 1712, intrinsicWidth: 2919, pixelHeight: 1712, pixelWidth: 2919, positionX: "center", positionY: "center", sizes: "min(660px, 100vw)", src: "https://framerusercontent.com/images/6A2CTVq96z2nXW9wTPr4Zr5UlM.jpg", srcSet: "https://framerusercontent.com/images/6A2CTVq96z2nXW9wTPr4Zr5UlM.jpg?scale-down-to=512 512w, https://framerusercontent.com/images/6A2CTVq96z2nXW9wTPr4Zr5UlM.jpg?scale-down-to=1024 1024w, https://framerusercontent.com/images/6A2CTVq96z2nXW9wTPr4Zr5UlM.jpg?scale-down-to=2048 2048w, https://framerusercontent.com/images/6A2CTVq96z2nXW9wTPr4Zr5UlM.jpg 2919w"}} className={"framer-16rhwz6"} layoutDependency={layoutDependency} layoutId={"UMM0TWpPg"}/></motion.div></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-umRDU [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-umRDU .framer-1vvms27 { display: block; }", ".framer-umRDU .framer-12r2jh7 { align-content: flex-start; align-items: flex-start; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 0px; height: min-content; justify-content: center; padding: 0px 0px 0px 0px; position: relative; width: min-content; }", ".framer-umRDU .framer-16rhwz6 { aspect-ratio: 3.0364372469635628 / 1; flex: none; height: var(--framer-aspect-ratio-supported, 217px); position: relative; width: 660px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-umRDU .framer-12r2jh7 { gap: 0px; } .framer-umRDU .framer-12r2jh7 > * { margin: 0px; margin-left: calc(0px / 2); margin-right: calc(0px / 2); } .framer-umRDU .framer-12r2jh7 > :first-child { margin-left: 0px; } .framer-umRDU .framer-12r2jh7 > :last-child { margin-right: 0px; } }", ".framer-umRDU.framer-v-67tri1 .framer-16rhwz6 { order: 0; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 217
 * @framerIntrinsicWidth 660
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"BridPQHFS":{"layout":["auto","auto"]}}}
 * @framerImmutableVariables true
 */
const FramerSMtqMCPrI: React.ComponentType<Props> = withCSS(Component, css, "framer-umRDU") as typeof Component;
export default FramerSMtqMCPrI;

FramerSMtqMCPrI.displayName = "Cubo Logo Footer";

FramerSMtqMCPrI.defaultProps = {height: 217, width: 660};

addPropertyControls(FramerSMtqMCPrI, {variant: {options: ["gtiYcpvr3", "BridPQHFS"], optionTitles: ["logo desktop", "logo mobile"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(FramerSMtqMCPrI, [])